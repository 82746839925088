import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'objex-micro';
  imageFirstMobile = '.././assets/mobile/1.png';
  imageFirstDesktop = '.././assets/desktop/1.png';
  imagesMobile = ['.././assets/mobile/2.png', '.././assets/mobile/3.png', '.././assets/mobile/4.png', '.././assets/mobile/5.png', '.././assets/mobile/6.png'];
  imagesDesktop = ['.././assets/desktop/2.png', '.././assets/desktop/3.png', '.././assets/desktop/4.png', '.././assets/desktop/5.png', '.././assets/desktop/6.png'];
  imagesC = ['1', '2', '3', '4', '5'];
  bgImage = 'url(' + '.././assets/6.png' + ')';
}
